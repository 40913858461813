import React, { lazy, memo } from 'react'
import Suspense from 'components/Public/Suspense'

const Gateway = memo(({ gateway, config, onClick, ...props }) => {
  const Gateway = lazy(() => import(`./gateways/${gateway}`))

  return (
    <Suspense {...props}>
      <Gateway config={config} onClick={onClick} />
    </Suspense>
  )
})

export default Gateway
