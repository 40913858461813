import React, { memo, useEffect } from 'react'
import useApi from 'hooks/useApi'
import { getAllPaymentGateways } from 'actions/paymentGateway'
import { paymentGateways } from 'constants/paymentGateways'
import Gateway from './gateway'
import { payBooking } from 'actions/booking'
import { useLoading } from 'hooks/useLoading'
import { Card } from '@material-ui/core'
  
const PaymentBooking = memo(({ bookingId, search, classes }) => {
  const [gateways] = useApi(() => Promise.all([getAllPaymentGateways()]))
  const [, setLoading] = useLoading()

  const onSelect = (gateway) => async () => {
    setLoading(true)
    const res = await payBooking(bookingId, new URLSearchParams(search).toString(), gateway)
    window.location = res.data?.url
  }

  useEffect(() => {
    if (gateways && gateways?.data.length === 1) {
      onSelect(gateways?.data.shift().id)()
    }
  }, [gateways])

  return (
    <Card className={classes.card}>
      {paymentGateways
        .filter((pg) => gateways?.data.find((g) => g.id === pg))
        .map((pg) => (
          <Gateway key={pg} gateway={pg} config={gateways?.data.find((g) => g.id === pg)} onClick={onSelect(pg)} />
        ))}
    </Card>
  )
})

export default PaymentBooking
