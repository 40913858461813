import React from 'react'
import { Router } from '@gatsbyjs/reach-router'
import Layout from 'components/Layout/Booking'
import BookingSecuredRoute from 'components/BookingSecuredRoute'
import { AuthProvider } from 'hooks/useAuth'
import { LoadingProvider } from 'hooks/useLoading'
import routes from 'routes/booking'

const BookingPage = () => {
  const LayoutedComponent = (Component, title, L = Layout) => (props) => (
    <L title={title} {...props}>
      <Component {...props} />
    </L>
  )

  return (
    <AuthProvider>
      <LoadingProvider>
        <Router>
          {routes.map(({ path, component, title, layout, ...props }) => (
            <BookingSecuredRoute key={path} path={path} component={LayoutedComponent(component, title, layout)} {...props} />
          ))}
        </Router>
      </LoadingProvider>
    </AuthProvider>
  )
}

export default BookingPage
