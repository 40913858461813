import React, { memo } from 'react'
import useApi from 'hooks/useApi'
import { getAllPaymentGateways } from 'actions/paymentGateway'
import { paymentGateways } from 'constants/paymentGateways'
import Gateway from './gateway'
import { payBooking } from 'actions/booking'
import { useLoading } from 'hooks/useLoading'

const PaymentBooking = memo(({ bookingId, location: { search } }) => {
  const [gateways] = useApi(() => Promise.all([getAllPaymentGateways()]))
  const query = new URLSearchParams(search)
  const [, setLoading] = useLoading()

  const onSelect = (gateway) => async () => {
    setLoading(true)
    const res = await payBooking(bookingId, query.toString(), gateway)
    window.location = res.data?.url
  }

  return (
    <>
      {paymentGateways
        .filter((pg) => gateways?.data.find((g) => g.id === pg))
        .map((pg) => (
          <Gateway key={pg} gateway={pg} config={gateways?.data.find((g) => g.id === pg)} onClick={onSelect(pg)} />
        ))}
    </>
  )
})

export default PaymentBooking
