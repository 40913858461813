import React, { memo, useEffect } from 'react'
import useApi from 'hooks/useApi'
import { statusConfirmed } from 'constants/bookings'
import Gateways from './gateways'
import { getBooking } from 'actions/booking'
import { getSettings } from 'actions/setting'
import { navigate } from 'gatsby'
import useStyles from './styles'

const PaymentBooking = memo(({ bookingId, location: { search }, ...props }) => {
  const [booking, settings] = useApi(() => Promise.all([getBooking(bookingId), getSettings()]))
  const classes = useStyles()

  useEffect(() => {
    if (booking && (booking.status === statusConfirmed.value || !booking.fee)) {
      navigate(`/booking/${bookingId}/confirmation${search ?? ''}`)
    }
  }, [booking])

  return (
    <>
      {settings?.paymentMessage ? <div className={classes.message}>{settings.paymentMessage}</div> : <></>}
      {booking?.status === statusConfirmed.value || !booking?.fee ? (
        <></>
      ) : (
        <Gateways classes={classes} bookingId={bookingId} search={search} {...props} />
      )}
    </>
  )
})

export default PaymentBooking
